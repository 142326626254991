import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Section from "../../components/Section"
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button"
import Headline from "../../components/Headline"
import { getFeaturedItem, getFeaturedItems } from "../../services/utility"
import Teaser from "../../components/Teaser"
import Feature from "../../components/Feature";

export default class WarriorWorship extends React.Component {
  render() {
    let albums = this.props.data.albums.edges.map(({ node }) => node);
    let chordCharts = albums.filter(function(item) { return (item.chordChart && item.chordChart.file && item.chordChart.file.url ) });
    let spotifyAlbums = albums.filter(function(item, index) { return (item.spotifyEmbedCode && index < 3) });
    let top = getFeaturedItem(this.props.data.top);
    let devotional = getFeaturedItem(this.props.data.devotional);
    let featuredVideos = getFeaturedItems(this.props.data.videos);
    return ( 
      <Layout>
        <Seo title="Warrior Worship" />
        {top && <Section className="primary" useContainer={false}>
          <Feature content={top} centerVertically={true} />
        </Section> }
        {featuredVideos && <Section className="white" useCol={false}>
          <Col xs={12}><Headline>Warrior Worship Music Videos</Headline></Col>
          {featuredVideos.map((video) => {
            return (
              <Col md={4} key={"video-" + video.slug}>
                <Teaser content={video} />
              </Col>
            );
          })}
          <Col xs={12} className="text-center"><Button href="/warrior-worship/videos" variant="primary" className="px-4 font-weight-bold">SHOW ALL</Button></Col>          
        </Section> }
        {devotional && <Section className="secondary devotional" useCol={false} centerVertically={true}>
          <Col xs={8} lg={6}>
            <Headline>{devotional.name}</Headline>
            { devotional.description && <div dangerouslySetInnerHTML={{
                __html: devotional.description.childMarkdownRemark.html,
              }}
            />}
            <p className="text-center mb-5 pb-3"><Button variant="outline-light" href={devotional.file.file.url} target="__blank" download={devotional.file.title}>Download</Button></p>
          </Col>
          <Col xs={6} lg={6}>
            <a href={devotional.file.file.url} target="__blank" download={devotional.file.title}><GatsbyImage className="mx-auto devotionalImage" image={getImage(devotional.image)} alt={devotional.image.title} /></a>
          </Col>
        </Section>}
        <Section className="white" useCol={false}>
          <Col xs={12} className="pb-3">
            <Headline center={true}>Check us out on Spotify</Headline>
          </Col>
          {spotifyAlbums.map((album) => {
            return (
              <Col md={3} className="spotifyEmbed" key={"album-" + album.slug}>
              <div dangerouslySetInnerHTML={{
                    __html: album.spotifyEmbedCode,
                  }}
                />
              </Col>
            );
          })}
          <Col xs={12} className="text-center">
            <p className="pt-4"><a href="https://open.spotify.com/artist/2KPr90nJVDu4GCKQvYIoId" target="__blank">Click here to check out all our albums on Spotify</a></p>
            <Button target="__blank" href="https://open.spotify.com/artist/2KPr90nJVDu4GCKQvYIoId" variant="primary" className="px-4 font-weight-bold">SPOTIFY</Button>
          </Col>
        </Section>
        <Section useCol={false} className="chord-charts">
          <Col xs={12}>
            <Headline center={true}>Warrior Worship Chord Charts</Headline>
            <p className="text-center">Click to download the chord charts for your favorite album!</p>
          </Col>
          {chordCharts.map((album) => {
            return (
              <Col md={3} key={"chart-" + album.slug}>
                <a href={album.chordChart.file.url} target="__blank" download={album.chordChart.title}><GatsbyImage className="mx-auto albumCover" image={getImage(album.albumCover)} alt={album.albumCover.title} /></a>
              </Col>
            );
          })}
        </Section>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    top: allContentfulFeatured(filter: {siteSection: {eq: "Warrior Worship - Top"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    },
    devotional: allContentfulFeatured(filter: {siteSection: {eq: "Warrior Worship - Devotional"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    },    
    videos: allContentfulFeatured(filter: {siteSection: {eq: "Warrior Worship - Music Videos"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    },      
    albums: allContentfulWarriorWorshipAlbum(sort: {order: DESC, fields: releaseDate}) {
      edges {
        node {
          name
          slug
          albumCover {
            title
            gatsbyImageData(
              layout: CONSTRAINED
              width: 800
              )
          }
          chordChart {
            title
            file {
              url
            }
          }
          spotifyEmbedCode
        }
      }
    }
  }
`
